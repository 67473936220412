@import "./overrides.css";
@import "../assets/fonts/Inter/stylesheet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-[100%] text-sm;
}

body,
#root {
  @apply min-h-[100%] h-[100%] flex flex-col bg-secondaryBg;
}

body.ru .ru-hidden {
  @apply hidden;
}

body.en .en-hidden {
  @apply hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}
